import { stringify } from 'querystring';
import React, { FunctionComponent, Fragment, useContext, useEffect, useState } from 'react';

import {FormContext} from '../../context/FormContext';
import FormSection from '../../interfaces/FormSection';
import ProgressItem from './ProgressItem';
import './ProgressTrack.scss';
interface IProps {
  sections:FormSection[];
  currentStep:number;
  goToStep(number:number):void;
  formErrors:Map<string, boolean>;
  finished:boolean;
}



const ProgressTrack:FunctionComponent<IProps> = props => {

    
  const formContext = useContext(FormContext);

  
  let [maxStep, setMaxStep] = useState<number>(getDefaultStateNumber('maxStep'));
  let [formErrors, setFormErrors] = useState<Map<string, boolean>>(new Map());
  let [totalSteps, setTotalSteps] = useState<number>(props.sections.length);


  function getDefaultStateNumber(key:string):number {
    return Number(JSON.parse(sessionStorage.getItem(key) || '0'));
  }

  React.useEffect(() => {
    if(props.currentStep > maxStep) {
        setMaxStep(props.currentStep);
        sessionStorage.setItem("maxStep", JSON.stringify(props.currentStep));
    }
  }, [props.currentStep])
  
  React.useEffect(() => {
    if(props.sections.length === maxStep) {
      setMaxStep(props.sections.length -1);
    }
  }, [props.sections.length])

  function getStepClasses(step:number):string {
    let progressClass:string = '';
    if (step == props.currentStep) {
        progressClass = progressClass + 'current-step '
    }
    if (step <= maxStep) {
        progressClass = progressClass + 'unlocked-step '
    }
    if (step > maxStep) {
        progressClass = progressClass + 'locked-step '
    }
    if (step == maxStep) {
      progressClass = progressClass + 'max-step '
    }
    return progressClass;
  }
      
  function onClickHandler(step:number) {
    if(step <= maxStep) {
        props.goToStep(step);
    }
  }

  function hasError(alias:string, formErrors:Map<string, boolean>, index:number) {
      if(index <= maxStep && index != props.currentStep) {
        if (formErrors.get(alias) === false) {
            return true;
        } else {
            return false;
        }
      }
      return false;
  }


  React.useEffect(() => {
    setFormErrors(props.formErrors);
  }, [formContext.formRevision, props.formErrors])

  return (
      <div className={"progress-track " + (props.finished ? 'finished' : '')}>
          <ul>
          {props.sections.map((section:FormSection, index:number) => 
            <ProgressItem key={"progress-" + section.alias} goToStep={props.goToStep} alias={section.alias} label={section.progressLabel} hasError={(formErrors.get(section.alias) !== true && index <= maxStep) || false} index={index} currentStep={props.currentStep} maxStep={maxStep} />
          )}
          </ul>
      </div>
  );
}

export default ProgressTrack;