import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';

import ChurchinvestorsFundTheme from './ChurchInvestorsFund/ChurchInvestorsFundTheme';

interface IProps {
  theme:string;
  children?: React.ReactNode;
  resetApp():void;
}


const Theme:FunctionComponent<IProps> = props => {

  return (
    <Fragment> 
        {props.theme === 'cif' &&
            <ChurchinvestorsFundTheme resetApp={props.resetApp}>
                {props.children}
            </ChurchinvestorsFundTheme>
        }
    </Fragment>
    )
}

export default Theme;