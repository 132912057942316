import React, { Component, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';

import './Table.scss';
import ITable from '../interfaces/Table';
import { FormContext } from '../../../context/FormContext';
import TableRow from '../interfaces/TableRow';
import { table } from 'console';
import KeyReplace from '../../KeyReplace';
import VisibilityService from '../../../service/VisibilityService';

interface IProps {
  component: ITable;
  parentAlias: string;
}

const Table: FunctionComponent<IProps> = props => {

  const formContext = useContext(FormContext);

  let [rows, setRows] = useState<JSX.Element[]>([]);
  let [subTableKeys, setSubTableKeys] = useState<Map<string, JSX.Element[]>>(new Map());
  let [subTableArray, setSubTableArray] = useState<JSX.Element[][]>([]);

  function replaceText(text: string): string {
    let newString = text;
    if (formContext.formData) {
      Array.from(formContext.formData).forEach((value: [string, string]) => {

        newString = newString.replaceAll("{" + value[0] + "}", value[1])

      }
      )
      //go through one more time incase there is a value that also needs replacing again
      Array.from(formContext.formData).forEach((value: [string, string]) => {

        newString = newString.replaceAll("{" + value[0] + "}", value[1])

      }
      )
    }
    if (newString !== text) {
      newString = newString.replaceAll(/ *\{[^)]*\} */g, "");
    }
    return newString;


  }

  function showIfWrapper(formItem: any): boolean {
    if (formItem.showIf.includes("this")) {
      let copy = JSON.parse(JSON.stringify(formItem));
      copy.showIf = copy.showIf.replaceAll("this", props.parentAlias).replaceAll(formContext.formAlias + ".", "");
      let show = VisibilityService.showIf(copy, formContext);

      return show;
    } else {
      let show = VisibilityService.showIf(formItem, formContext);

      return show;
    }

  }

  function getRows(subTableKey?: string, count?: number) {
    let rows: JSX.Element[] = [];

    
      props.component.rows.map((row: TableRow, index: number) => {
        let visible = true;
        if (row.showIf) {
          let rowCopy:TableRow = JSON.parse(JSON.stringify(row));
          rowCopy.showIf = rowCopy.showIf?.replaceAll('{count}', String(count || 0));
          visible = showIfWrapper(rowCopy);
        }
        let rowValue = row.value.replaceAll('{count}', String((count || 0)));
        let externalKey = rowValue.startsWith('{' + (formContext.formAlias || ''));
        
        if (visible) {
          
          if (subTableKey && !externalKey) {
            rowValue = rowValue.replaceAll('{', '{' + subTableKey + '.');
          }
          let replacedText: string = replaceText(rowValue);

          if (replacedText !== row.value && replacedText !== '' && !replacedText.includes('{')) {

            rows.push(
              <tr key={props.component.title + 'row-' + index}>
                <td><KeyReplace string={row.label} /></td>
                <td>{replacedText}</td>
              </tr>
            );
          }
        }


      }
      );
    

    return rows;
  }




  function getSubTableKeys() {
    let subTableKeys: Map<string, JSX.Element[]> = new Map();
    let keys = formContext.formData?.keys();
    let dataSourceLength = props.component.dataSource?.length || 0;
    let newSubTableArray: JSX.Element[][] = [];
    if (keys && props.component.dataSource) {
      Array.from(keys).forEach((key: string) => {
        if (key.startsWith(props.component.dataSource || '')) {
          let modifiedKey = key.substr(dataSourceLength + 1).split('.')[0];
          subTableKeys.set(modifiedKey, []);
        }
      });
      Array.from(subTableKeys.keys()).forEach((key: string, index: number) => {
        let rows = getRows(props.component.dataSource + '.' + key, index + 1);
        newSubTableArray.push(rows);
      });
    }

    return newSubTableArray;
  }

  React.useEffect(() => {
    if (props.component.dataSource) {
      setSubTableArray(getSubTableKeys());

    } else {
      setRows(getRows());
    }
  }, [formContext.formRevision]);


  return (
    <Fragment>
      {!props.component.dataSource &&
        <div style={{ display: (rows.length > 0 ? 'block' : 'none') }} className="table">
          <h2><KeyReplace string={props.component.title} /></h2>
          <table>
            <tbody>
              {rows.map((row: JSX.Element) => row)}
            </tbody>
          </table>
        </div>}
      {props.component.dataSource &&
        <div>
          {subTableArray.map((subTable, index) =>
            <div className="table" key={'sub-' + index}>
              <h2><KeyReplace string={props.component.subTitle || ''} count={index + 1} /></h2>
              <table>
                <tbody>
                  {subTable.map((row: JSX.Element) => row)}
                </tbody>
              </table>
            </div>
          )}
        </div>
      }
    </Fragment>
  );
}

export default Table;