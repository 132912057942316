import React, { Component, FunctionComponent, useContext, useEffect, useState } from 'react';
import { FormContext } from '../../../context/FormContext';

import IDecider from '../interfaces/Decider';

interface IProps {
    component: IDecider;
    parentAlias: string;
}

const Decider: FunctionComponent<IProps> = props => {

    const formContext = useContext(FormContext);

    function evaluateExpression(expression:string):boolean {
        let condition = props.component.condition;
        let conditionArray = condition.split('&&');

        let evaluates = [];

        for (let i = 0; i < conditionArray.length; i++) {
            let conditionParts = conditionArray[i].split(" ");
            //console.log(conditionParts[0]);
            //console.log(conditionParts[2]);
            let a = (formContext.formData?.get((conditionParts[0].startsWith('system') ? '' : 'investment-application.') + conditionParts[0]) || '').toLowerCase();
            
            let b = (formContext.formData?.get((conditionParts[2].startsWith('system') ? '' : 'investment-application.') + conditionParts[2]) || '').toLowerCase();
            //console.log(b);
            if (!b) {
                b = conditionParts[2].toLowerCase();
            }
            let comparator = conditionParts[1];
            //console.log(a + " " + comparator + " " + b);
            if (comparator === 'eq') {
                evaluates.push((a == b));
            } else if (comparator === 'neq') {
                evaluates.push((a != b));
            } else if (comparator === 'includes') {
                let valueArray = a?.split(",") || [];
                //console.log(valueArray.includes(b));
                //console.log(b);
                evaluates.push(valueArray.includes(b));
            }

        }

        
        for (let j = 0; j < evaluates.length; j++) {
            //console.log(props.component.alias + " eval: " + evaluates[j]);
            if (!evaluates[j]) {
                return false;
            }
        }
        return true;
    }

    function setSystemValue(value:string) {
        if(props.component.systemValue && formContext.updateFormData) {
          formContext.updateFormData("system." + props.component.systemValue , value );
        }
      }

    React.useEffect(() => {
        //console.log('decider run');
        let condition = evaluateExpression(props.component.condition);
        setSystemValue((condition ? 'true' : 'false'));
    }, []);

    React.useEffect(() => {
        //console.log('decider run');
        let condition = evaluateExpression(props.component.condition);
        let existingValue = formContext.formData?.get("system." + props.component.systemValue);
        if (String(condition) != existingValue) {
            setSystemValue((condition ? 'true' : 'false'));
        }
    }, [formContext.formRevision]);


    return (
        <></>
    );
}

export default Decider;