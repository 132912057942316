import React, { Component, FunctionComponent, useEffect, useState } from 'react';
import axios from 'axios';
import './FormItemBigImageButtons.scss';
import IFormItemBigImageButtons from '../../../interfaces/FormItemBigImageButtons';
import FormItemChoice from '../../../interfaces/FormItemChoice';
import BigImageButton from './BigImageButton';

interface IProps {
  component:IFormItemBigImageButtons;
  parentAlias:string;
  checkedValue:string;
  onValueChange(value:string):void;
}

const FormItemBigImageButtons:FunctionComponent<IProps> = props => {
  return (
    <div className="big-image-buttons">
        <ul>
          {props.component.choices.map((choice:FormItemChoice, index:number) => 
              <li key={props.component.alias + '-' + index}><BigImageButton checkedValue={props.checkedValue} count={index + 1} name={props.parentAlias + "." + props.component.alias} onValueChange={props.onValueChange} choice={choice} /></li>
          )}
        </ul>
    </div>
  );
}

export default FormItemBigImageButtons;