import React, { Component, FunctionComponent, useEffect, useState } from 'react';

import './CheckBox.scss';

interface IProps {
  checked:boolean;
}

const CheckBox:FunctionComponent<IProps> = props => {
  
  return (
    <div className="check-box">
        <div className={'check-square ' + (props.checked ? 'checked' : 'unchecked')}>
            
        </div>
    </div>
  );
}

export default CheckBox;