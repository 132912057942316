import React, { Component, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';

import './Footnote.scss';
import IFootnote from '../interfaces/Footnote';
import {FormContext} from '../../../context/FormContext';
import KeyReplace from '../../KeyReplace';


interface IProps {
  component:IFootnote;
}

const Footnote:FunctionComponent<IProps> = props => {
  
  const formContext = useContext(FormContext);
  
  


  return (
    <div className="footnote">
        <KeyReplace string={props.component.content} />
    </div>
  );
}

export default Footnote;