import { FunctionComponent, useContext } from 'react';
import {FormContext} from '../../context/FormContext';
import './Loader.scss';


interface IProps {
  
}



const Loader:FunctionComponent<IProps> = props => {

  const formContext = useContext(FormContext);
      
  return (
    <div className="loader-overlay">
        <div className="loader-overlay-background"></div>
        <div className="loader-box">Processing Your Request <div className="lds-ring"><div></div><div></div><div></div><div></div></div></div>
    </div>
  );
}

export default Loader;