import React, { Component, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import './Church-Investors-Fund.scss';


interface IProps {
  
}

const AcknowledgementCa:FunctionComponent<IProps> = props => {
  

  return (
    <div className="church-investors">
      <h1>CANADA ACKNOWLEDGEMENT</h1>
      <div className="scroll-wrapper">
          <div className="scroll-area">
<p>1) You agree to purchase the Bond indicated by the information you have provided in this Subscription Agreement.</p>
<p>2) Each person signing below declares under penalty of perjury that such person(s): has received and read the "Terms and Conditions of Subscription for Debt Securities of Church Investors Fund, Ltd.".</p>  
<p>You understand and agree to these terms and conditions. Furthermore, each person signing below acknowledges that their signature(s), as signed below, will be used for identity verification purposes when requesting investment activities.</p>
<p>3) Interest rates are listed on our rate page, which forms part of this Subscription Agreement.  The applicable interest rate depends on the type of investment (e.g., Bond or Foundation Agreement), the term of the investment (e.g., one (1) year or five (5) years) and the amount invested.  The interest rates on the rate page are current as of the date on the rate page and are subject to change without notice to you.</p>
<p>4) Beneficiary Designation and Spousal Consent.  If you have selected a Revocable Gift Bond, you request that Church Investors Fund designate the church or organization listed in Part 3 as beneficiary to receive the principal and any unpaid accrued interest on your Bond at your death. You may change or revoke this beneficiary designation at any time prior to your death. The terms of this beneficiary designation, your Revocable Gift Bond and this Subscription Agreement shall be binding on your heirs, beneficiaries and legal representatives. In addition, you represent either (i) that you are unmarried, or (ii) that your spouse has signed either this Subscription Agreement as a Joint Investor, OR spouse has signed below, giving to the Primary Investor any interest they have in the funds to be invested in the Bond applied for in this Subscription Agreement and agrees to the naming of the beneficiary in Part 3. The spouse also acknowledges that they have received a fair and reasonable disclosure of the Primary Investor's property and financial obligations.  The spouse acknowledges that they shall have no claim against Church Investors Fund or the beneficiary identified in Part 3 of this Subscription Agreement for any payment made to the beneficiary.</p>
<p>5) Liability of Joint Investors.  The undersigned Investor and Joint Investor shall be jointly and severally liable under this Subscription Agreement.  Church Investors Fund is authorized to act upon the instructions and directions of either Investor in all matters, including redemption requests by cheque or otherwise.  In addition, if the Investors have selected a Revocable Gift Bond, each Investor represents that the other Investor is his or her spouse.</p>
<p>6) If you have selected a Building Fund Bond or Ministry Fund Bond, each person signing declares under penalty of perjury that the Applicant meets the definition of “Qualified Organization”. In addition, if the Applicant has selected a Ministry Fund Bond, Applicant agrees to indemnify Church Investors Fund for claims related to its decision to approve or reject a purpose, approve or reject a change to a purpose, permit redemption without a penalty, or impose a penalty, and for the application or use of redeemed funds.</p>
<p>7) By signing this Subscription Agreement and under penalties of perjury, each of you certify that: 1) the Social Insurance or Taxpayer Identification Number you list under your name in Part 1B of this Subscription Agreement is correct; and 2) You are not subject to backup withholding because: (a) You are exempt from backup withholding, or (b) The Canada Revenue Agency has not notified you that part of your dividend and interest is to be withheld as a result of your failure to report all dividend and interest income, or (c) the CRA has notified you that you are no longer subject to backup withholding.  NOTE: The CRA does not require your consent to any provision of this document other than the certifications required to avoid backup withholding.</p> 
    </div>
    </div>
    </div>
  );
}

export default AcknowledgementCa;