import React, { Component, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import './Church-Investors-Fund.scss';


interface IProps {
  
}

const AcknowledgementUs:FunctionComponent<IProps> = props => {
  
  


  return (
    <div className="church-investors">
        <h1>US ACKNOWLEDGEMENT</h1>
        <div className="scroll-wrapper">
          <div className="scroll-area">
          <p>1) The offer and sale of Church Investors Fund securities (“Certificates”) are subject to the terms and conditions of the most recent Church Investors Fund Offering Circular, as it may be supplemented, amended and restated (“Offering Circular”), which is incorporated herein in full by this reference. Church Investors Fund reserves the right to refuse your application and not offer to sell any Certificate to you for any reason.</p>

<p>2) You agree to purchase the Certificate indicated by the information you have provided in this Purchase Application and Agreement. (Pennsylvania residents who purchase Certificates have the right to withdraw from the purchase pursuant to Section 207(m) of the Pennsylvania Securities Act of 1972. Please see page iv of Offering Circular for additional details.)</p>

<p>3) Each person signing below declares under penalty of perjury that the Applicant(s): 1) meets the definition of “Limited Class of Investors” set forth in the Offering Circular, 2) has reviewed the Offering Circular and the Church Investors Fund financial statements included in it. You also had the opportunity to ask questions and receive answers about the Offering Circular and financial statements, the risks of investing in a Certificate, and the terms of the Certificate you are applying for; and 3) agrees to the terms and conditions described in the Offering Circular. Furthermore, each person signing below acknowledges that their signature(s), as signed below, will be used for identity verification purposes when requesting investment activities.</p>

<p>4) This application serves as a Substitute W-9 Form. Church Investors Fund is required to obtain your correct Social Security Number (SSN) in order to report to the Internal Revenue Service (IRS) interest paid to you. Church Investors Fund, like any of other payer of interest or dividends, must withhold and pay to the IRS a percentage of the interest paid to you if you fail to furnish Church Investors Fund with the correct SSN or if you fail to sign the acknowledgement at the bottom of this Purchase Application. This is referred to as “backup withholding.” For more information on backup withholding, visit www.IRS.gov.</p>

<p>5) Beneficiary Designation and Spousal Consent. If you have selected a Revocable Gift Certificate, you request that Church Investors Fund designate the church or organization listed in Part 3 as beneficiary to receive the principal and any unpaid accrued interest on your Certificate at your death. You may change or revoke this beneficiary designation at any time. The terms of this beneficiary designation, your Revocable Gift Certificate and this Purchase Application and Agreement shall be binding on your heirs, beneficiaries and legal representatives. In addition, you represent either (i) that you are unmarried, or (ii) that your spouse has signed either this Purchase Application and Agreement as a Joint Applicant, OR spouse has signed below, giving to the Primary Applicant any interest they have in the funds to be invested in the Certificate applied for in this Purchase Application and Agreement and agrees to the naming of the beneficiary in Part 3. The spouse also acknowledges that they have received a fair and reasonable disclosure of the Primary Applicant's property and financial obligations. The spouse acknowledges that they shall have no claim against Church Investors Fund or the beneficiary identified in Part 3 of this Purchase Application and Agreement for any payment made to the beneficiary.</p>

<p>6) Liability of Joint Applicants. The undersigned Applicant and each Joint Applicant shall be jointly and severally liable under this Purchase Application and Agreement. Church Investors Fund is authorized to act upon the instructions and directions of any Applicant in all matters, including redemption requests by check or otherwise. In addition, if the Applicants have selected a Revocable Gift Certificate, each Applicant represents that the only other Applicant is his or her spouse.</p>

<p>7) If you have selected a Building Fund Certificate or Ministry Fund Certificate, each person signing below declares under penalty of perjury that the Applicant meets the definition of “Qualified Ministry” set forth in the Offering Circular. In addition, if the Applicant has selected a Ministry Fund Certificate, Applicant agrees to indemnify Church Investors Fund for claims related to its decision to approve or reject a purpose, approve or reject a change to a purpose, permit redemption without a penalty, or impose a penalty, and for the application or use of redeemed funds.</p>

<p>8) By signing this Purchase Application and Agreement and under penalties of perjury, each of you certify that: 1) the Social Security or Taxpayer Identification Number you list under your name in Part 1B of this Purchase Application and Agreement is correct; 2) You are not subject to backup withholding because: (a) You are exempt from backup withholding, or (b) The Internal Revenue Service has not notified you that part of your dividend and interest is to be withheld as a result of your failure to report all dividend and interest income, or (c) the IRS has notified you that you are no longer subject to backup withholding; and 3) you are a U.S. citizen or other U.S. person (as defined in the instructions to Form W-9). NOTE: The Internal Revenue Service does not require your consent to any provision of this document other than the certifications required to avoid backup withholding.</p>
          </div>
        </div>
        
    </div>
  );
}

export default AcknowledgementUs;