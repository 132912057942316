import React, { FunctionComponent, Fragment, useContext, useEffect, useState } from 'react';
import FormMap from '../../interfaces/FormMap';
import IFormSection from '../../interfaces/FormSection';
import FormSection from '../FormSection/FormSection';
import ReactHtmlParser from 'html-react-parser'; 
import {FormContext} from '../../context/FormContext';

interface IProps {
  string:string;
  count?:number;
}



const KeyReplace:FunctionComponent<IProps> = props => {

    
  const formContext = useContext(FormContext);


  function replaceText(text:string, count:number|undefined):string {
    if(count) {
      text = text.replace("{count}", count.toString());
    }
    
  
    let newString = text;
    if (formContext.formData) {
      Array.from(formContext.formData).forEach((value:[string,string]) => 
        newString = newString.replaceAll("{" + value[0] + "}", value[1])
      )
    }
  
    return newString;
  }
    

      
  return (
      <Fragment>
        {ReactHtmlParser (replaceText(props.string, props.count))}
    </Fragment>
  );
}

export default KeyReplace;