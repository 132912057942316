import React, { Component, FunctionComponent, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import './FormItemBigVerticalButtons.scss';
import IFormItemBigVerticalButtons from '../../../interfaces/FormItemBigVerticalButtons';
import FormItemChoice from '../../../interfaces/FormItemChoice';
import BigVerticalButton from './BigVerticalButton';
import Group from '../interfaces/Group';
import { group } from 'console';
import { FormContext } from '../../../context/FormContext';
import KeyReplace from '../../KeyReplace';
import Product from '../RateChart/interface/Product';
import InvestmentCategory from '../../../interfaces/InvestmentCategory';
import InvestmentProduct from '../../../interfaces/InvestmentProduct';

interface IProps {
  component: IFormItemBigVerticalButtons;
  parentAlias: string;
  checkedValue: string;
  onValueChange(value: string): void;
}

const FormItemBigVerticalButtons: FunctionComponent<IProps> = props => {

  const formContext = useContext(FormContext);

  let [rateChart, setRateChart] = useState<InvestmentCategory[]>([]);
  let [groups, setGroups] = useState<Group[]>([]);
  //let [filteredItems, setFilteredItems] = useState<Group[]>([]);


  const fetchData = React.useCallback(() => {
    axios({
      "method": "GET",
      //"url": "//localhost:8080/formbuilder-api/getRateChartRates",
      "url": `//${process.env.REACT_APP_CIF_SERVICE_BASEPATH}/getRates`
    })
      .then((response) => {
        //console.log(response.data);
        let products: InvestmentCategory[] = response.data;
        //console.log(products);
        setRateChart(products);
        //let groups:Group[] = [];





        //setRateChart(groups);
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  React.useEffect(() => {
    if (props.component.dataType === 'rates') {
      fetchData()
    }
  }, [fetchData])

  function filterRateChart():Group[] {
    let groups:Group[] = [];
    let tags = props.component.tag?.split(',') || [];
    if (tags.length) {
      for (let i = 0; i < tags.length; i++) {
        tags[i] = formContext.formData?.get(formContext.formAlias + '.' + tags[i]) || tags[i];
      }
    }


    let checker = (arr: string[], target: string[]) => target.every(v => arr.includes(v));
    let products = rateChart;
    for (let i = 0; i < products.length; i++) {
      let group: Group = {} as Group;
      group.id = products[i].id;
      group.name = products[i].name;
      group.choices = [];
      if (group.name === 'Demand Investments' && tags.includes('us')) {
        group.footnote = 'Demand securities are not available for sale in Louisiana and South Carolina.';
      }
      for (let j = 0; j < products[i].investmentProducts.length; j++) {

        let item = products[i].investmentProducts[j];
        if (checker(item.tags, tags)) {
          let choice: FormItemChoice = {} as FormItemChoice;
          choice.id = item.id;
          choice.value = item.name;
          choice.label = item.name;
          choice.subLabel = '$' + item.investmentRates[0].minimumAmount + '  Minimum Investment';
          let range = '';
          if (item.investmentRates.length > 1) {
            range = `${item.investmentRates[0].annualYeild.toFixed(2)}% - ${item.investmentRates[item.investmentRates.length - 1].annualYeild.toFixed(2)}%`;
          } else {
            range = `${item.investmentRates[0].annualYeild.toFixed(2)}%`
          }
          
          choice.extraInfo = `${range} APY<sup>✝</sup>`;
          choice.systemValue = `minimum-investment|${item.investmentRates[0].minimumAmount},investment-category|${group.id},investment-duration|${item.investmentRates[0].duration}`;
          if (item.tags.includes('special')) {
            choice.special = true;
          } else {
            choice.special = false;
          }
          group.choices.push(choice);
        }



      }
      if (group.choices.length > 0) {
        groups.push(group);
      }

    }

    return groups
  }

  function showIf(formItem: any): boolean {

    // let show = true;
    let showList: boolean[] = [];
    let multitype = '';

    if (formItem.hasOwnProperty("showIf")) {
      let expressions: string[] = [];

      if (formItem.showIf.includes('||')) {

        multitype = "or";
        expressions = formItem.showIf.split("||");
      } else if (formItem.showIf.includes('&&')) {

        multitype = "and";
        expressions = formItem.showIf.split("&&");
      } else {
        expressions = formItem.showIf.split("||");
      }

      //console.log(expressions);
      expressions.forEach((expression: string) => {
        let expressionArray: string[] = expression.split(" ");
        //lets extract the fields
        let show = false;
        let value = formContext.formData?.get(formContext.formAlias + '.' + expressionArray[0]);
        value = value?.toLowerCase();
        let compareValue = expressionArray[2].replaceAll("+", " ");
        compareValue = compareValue.toLowerCase();
        //console.log(value + " " + expressionArray[1] + " " + compareValue);
        if (expressionArray[1] === 'eq') {

          if (value === compareValue) {
            show = true;
          }
        }
        if (expressionArray[1] === 'neq') {

          if (value != compareValue) {
            show = true;
          }
        }
        if (expressionArray[1] === 'gte') {

          if (Number(value) >= Number(compareValue)) {
            show = true;
          }
        }
        showList.push(show);
      }
      );




    }

    if (multitype != '') {

      if (multitype === 'or') {
        let final: boolean = false;
        showList.forEach((value: boolean) => {
          if (value) {
            final = true;
          }
        })
        return final;
      }
      if (multitype === 'and') {
        let final: boolean = true;
        showList.forEach((value: boolean) => {
          if (!value) {
            final = false;
          }
        })
        return final;
      }
    }
    if (showList.length === 0) {
      return true;
    }
    return showList[0];
  }

  function isGroupEmpty(group: Group): boolean {
    let isEmpty = true;
    props.component.choices.filter((choice: FormItemChoice) => choice.groupId === group.id).forEach((choice: FormItemChoice) => {
      if (showIf(choice)) {
        isEmpty = false;
      }
    }
    )

    return isEmpty;
  }

  return (
    <div className="big-vertical-buttons">
      <ul>
        {!props.component.groups && !props.component.dataType && props.component.choices.map((choice: FormItemChoice, index: number) =>
          showIf(choice) &&
          <li key={props.component.alias + "-" + index}><BigVerticalButton checkedValue={props.checkedValue} count={index + 1} name={props.parentAlias + "." + props.component.alias} onValueChange={props.onValueChange} choice={choice} /></li>
        )}
      </ul>
      {!props.component.dataType && props.component.groups &&
        props.component.groups.map((group: Group, indexParent: number) =>
          !isGroupEmpty(group) &&
          <div key={group.id + '-' + indexParent} className="big-vertical-button-group">
            <h2>{group.name}</h2>
            <ul>
              {props.component.choices.filter((choice: FormItemChoice) => choice.groupId === group.id)
                .map((choice: FormItemChoice, index: number) =>
                  showIf(choice) &&
                  <li key={props.component.alias + "-" + index}><BigVerticalButton checkedValue={props.checkedValue} count={(indexParent * 100) + index + 1} name={props.parentAlias + "." + props.component.alias} onValueChange={props.onValueChange} choice={choice} /></li>

                )}
            </ul>
            {group.footnote && <div className="footnote"><KeyReplace string={group.footnote} /></div>}
          </div>
        )
      }
      {props.component.dataType === 'rates' && filterRateChart().map((category: Group, indexParent: number) =>
        <div key={category.id + '-' + indexParent} className="big-vertical-button-group">
          <h2>{category.name}</h2>
          <ul>
            {category.choices != null && category.choices.map((product: FormItemChoice, index: number) =>

              <li key={props.component.alias + "-" + index}><BigVerticalButton checkedValue={props.checkedValue} count={(indexParent * 100) + index + 1} name={props.parentAlias + "." + props.component.alias} onValueChange={props.onValueChange} choice={product} /></li>

            )}
          </ul>
          {category.footnote && <div className="footnote"><KeyReplace string={category.footnote} /></div>}
        </div>
      )
      }
    </div>
  );
}

export default FormItemBigVerticalButtons;