import { stringify } from 'querystring';
import React, { FunctionComponent, Fragment, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {FormContext} from '../../../context/FormContext';
import './RateChart.scss';
import Product from './interface/Product';
import Rate from './interface/Rate';
import KeyReplace from '../../KeyReplace';
import { getMaxListeners } from 'process';

interface IProps {
  
}



const RateChart:FunctionComponent<IProps> = props => {

    
  const formContext = useContext(FormContext);

  let [rateChart, setRateChart] = useState<Product[]>([]);
  let [investment, setInvestment] = useState<Product>();

  const fetchData = React.useCallback(() => {
    axios({
      "method": "GET",
      //"url": "//localhost:8080/formbuilder-api/getRateChartRates",
      "url" : `//${process.env.REACT_APP_CIF_SERVICE_BASEPATH}/getRateChartRates`
    })
    .then((response) => {
      //console.log(response.data);
      let products:Product[] = response.data;
      //console.log(products);
      setRateChart(products);
    })
    .catch((error) => {
      console.log(error)
    })
  }, [])

  
  React.useEffect(() => {
    fetchData()
  }, [fetchData])
  
  React.useEffect(() => {
    let investmentChoice = formContext.formData?.get("investment-application.choose-investment.investment-select");
    setInvestment(rateChart.filter((product:Product) => product.name === investmentChoice)[0])
    
   },  [formContext.formRevision, rateChart])
  
  
  
  function getMaxAmount(index:number):string {
      let next = investment?.rates[index + 1];
      if (next) {
        return formatter.format(next.minimum - 1);
      } else {
          return "+";
      }
  }
  
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  return (
    
    <div className="rate-chart">
       {investment &&
        <div>
            
        <table>
            <tbody>
                <tr>
                    <th>{investment.name} Investment Amount</th>
                    <th>APY<sup>✝</sup></th>
                </tr>
          {investment.rates.map((rate:Rate, index:number) => 
            <tr key={'rate-' + index}>
                <td>{formatter.format(rate.minimum)}{(index + 1 < (investment?.rates.length || 0) ? ' to ' : '')}{getMaxAmount(index)}</td>
                <td>{parseFloat(String(rate.apy)).toFixed(2)}%</td>
            </tr>

          )}
          </tbody>
          </table>
          {formContext.formData?.get('system.country') === 'us' && <div className="foot-note">Investment rates are established according to policies set forth in the Offering Circular and are subject to change without notice.<br/>
✝  Interest rates quoted above are stated as APY (Annual Percentage Yield). However, interest is accrued using APR (Annual Percentage Rate). Should you have any questions regarding how interest is paid on Church Investors Fund Certificates/Bonds, feel free to reach out to our Church Investors Fund Staff.</div>}
{formContext.formData?.get('system.country') === 'ca' && <div className="foot-note">Rates are subject to change without notice.<br/>
✝  Interest rates quoted above are stated as APY (Annual Percentage Yield). However, interest is accrued using APR (Annual Percentage Rate). Should you have any questions regarding how interest is paid on Church Investors Fund Certificates/Bonds, feel free to reach out to our Church Investors Fund Staff.</div>}
          </div>
       }
    </div>
  );
}

export default RateChart;