import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import './FormSection.scss';
import IFormSection from '../../interfaces/FormSection';
import FormItemRenderer from '../FormItemRenderer/FormItemRenderer';
import { FormContext } from '../../context/FormContext';
import KeyReplace from '../KeyReplace';
interface IProps {
  formSection:IFormSection;
  parentAlias:string;
  isFormValid:boolean;
  sectionActive:boolean;
  formDirty:boolean;
  setFirstError?(alias:string):void;
  formValidator(isValid:boolean):void;
}


const FormSection:FunctionComponent<IProps> = props => {

  const formContext = useContext(FormContext);

  
  let [validators, setValidators] = useState<Map<string, boolean>>(new Map());

  function validate(alias:string, isValid:boolean) {
    
    let validatorList = validators;
    validatorList.set(alias, isValid);
    // if (alias.includes("investment-application.total-investment-amount")) {
    //   console.log(alias + " " + isValid);
    // }  
    
    setValidators(validatorList);  
  }

  function removeValidator(alias:string) {
    
    let validatorList = validators;
    let validatorsToRemove:string[] = [];
    //create a list of validators we need to remove
    validatorList.forEach((value: boolean, key: string, map: Map<string, boolean>) =>
      { 
      if (key.startsWith(alias)) {
          validatorsToRemove.push(key);
        }
      }
    ); 
    
    validatorsToRemove.forEach((key:string) => {
      validatorList.delete(key);
    });

    
    setValidators(validatorList);
  }
    
  React.useEffect(() => {
    //console.log(formContext.formRevision);
    //props.formValidator(isFormValid);
    let formIsValid = true;
    //if(props.formSection.alias.includes("total-investment-amount")) {
      //console.log(validators);
      //console.log(validators.get("investment-application.total-investment-amount.special"));
    //console.log(props.formSection.alias + " " + validators.size);
  //}
  let firstError = '';
    Array.from(validators).forEach((value:[string, boolean]) =>
      {if (!value[1]) {
        //console.log(value[0]);
        formIsValid = false;
        if(!firstError) {
          firstError = value[0];
          if(props.setFirstError) {
            props.setFirstError(firstError);
          }
          
        }
      }}
    );
    //if (alias.includes("investment-application.total-investment-amount")) {
    //console.log(validators);
    //console.log(props.formSection.alias + "- " + formIsValid);
    //}
    //setIsFormValid(formIsValid);  
    
    props.formValidator(formIsValid);
  

   }, [formContext.formRevision]);


  
  return (
    <div className="form-section"><div className="form-section-header"><h1><KeyReplace string={props.formSection.name} /></h1>
        <p><KeyReplace string={props.formSection.prompt} /></p></div>
        {props.formSection.formItems.map((formItem:any, index:number) =>
            <Fragment key={props.parentAlias + "." + props.formSection.alias + '-' + index}>
              
                <FormItemRenderer formDirty={props.formDirty} removeValidationHandler={removeValidator} validationHandler={validate} parentAlias={props.parentAlias + "." + props.formSection.alias} formItem={formItem} />
              
            </Fragment>
        )}
    </div>
  );
}

export default FormSection;