import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';
import './ChurchInvestorsFundTheme.scss';

interface IProps {
  children?: React.ReactNode;
  resetApp():void;
}


const ChurchinvestorsFundTheme:FunctionComponent<IProps> = props => {

  return (
    <Fragment> 
    <div className="app-wrapper">
    <div className="App">
      <div className="header">
        <div className="header-body"><img width="60" src="/CIF-Icon-Blue.svg" />
          <span className="app-title">Church Investors Fund</span> <span className="app-subtitle">Deposit Form</span>
          <div className={"header-buttons"}>
            {<a onClick={props.resetApp}>Reset Deposit Form</a>} <a href="#modal-help">Help</a>
          </div>
        </div>
         
      </div>
      
    </div>
       {props.children}
    </div>
    <div className="page-footer">
    <p><strong>US Residents:</strong> Please note that this is neither an offer to sell nor a solicitation of an offer to buy Church Investors Fund securities. Such an offer is made by our <a href="https://churchinvestorsfund.org/2021-annual-report_offering-circular-us/" target="_blank">Offering Circular</a> and only in those states where Church Investors Fund securities may lawfully be offered or sold. Church Investors Fund securities are subject to certain risk factors as described in the Offering Circular and are not F.D.I.C. or S.I.P.C. nor are they guaranteed by the NAB. <strong>Canada:</strong>
The purchase of Church Investors Fund, Ltd (CeIF) securities are subject to risks, which are described in the Church Investors Fund <a target="_blank" href="https://churchinvestorsfund.org/wp-content/uploads/2021-Annual-Report_Subscription-Agreement-Canada.pdf">Subscription Agreement</a>. This is neither an offer to sell nor a solicitation of an offer to buy Church Investors Fund, Ltd securities. Church Investors Fund, Ltd securities are not C.D.I.C. insured nor are they guaranteed by the NAB.</p>
     <p>2021 Church Investors Fund, Ltd. <a href="https://churchinvestorsfund.org/privacy-policy/">Privacy Policy</a></p> 

    </div>
    </Fragment>
    )
}

export default ChurchinvestorsFundTheme;