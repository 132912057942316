import React, { Fragment, FunctionComponent, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import './BigImageButton.scss';
import FormItemChoice from '../../../interfaces/FormItemChoice';
import { checkServerIdentity } from 'tls';
import CheckBox from '../CheckBox';
import { FormContext } from '../../../context/FormContext';

interface IProps {
  choice:FormItemChoice;
  name:string;
  count:number;
  checkedValue:string;
  onValueChange(value:string):void;
}

const BigImageButton:FunctionComponent<IProps> = props => {

    const formContext = useContext(FormContext);

    const [hover, setHover] = useState<boolean>(false);
    function onChangeHandler(e:React.ChangeEvent<HTMLInputElement>) {
      //console.log('test');
      addSystemValues();
      props.onValueChange(e.target.value);
    }

    function addSystemValues() {
      if (props.choice.hasOwnProperty('systemValue') && formContext.updateFormData) {
        let systemValues:string[] | undefined = props.choice.systemValue?.split(',');
        systemValues?.forEach((value:string) => {
        let systemValue:string[] | undefined = value?.split('|');
        if(systemValue) {
          if(formContext.updateFormData) {
            formContext.updateFormData("system." + systemValue[0] ,systemValue[1]);
          }
        }
      })
        
        
      }
      
    }

    function onClick() {
      addSystemValues();
      props.onValueChange(props.choice.value);
    }

    function getId():string {
        return props.name + props.count;
    }

    function isChecked():boolean {
      return props.choice.value === props.checkedValue;
    }

    
  return (
    
        <div onClick={onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} className={"big-image-button " + (hover ? 'hover' : '')}>
          <div className="image-frame">
            <img src={`/${props.choice.image}`} />
          </div>
          <input onChange={onChangeHandler} type="radio" id={getId()} name={props.name} value={props.choice.value}  checked={isChecked()} />
          <label htmlFor={getId()}>{props.choice.label}</label>
          <CheckBox checked={isChecked()} />
        </div>
    
  );
}

export default BigImageButton;