import React, { Component, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';

import IDocumentDownload from '../interfaces/DocumentDownload';
import {FormContext} from '../../../context/FormContext';
import './DocumentDownload.scss';

interface IProps {
  component:IDocumentDownload;
}

const DocumentDownload:FunctionComponent<IProps> = props => {
  
  const formContext = useContext(FormContext);

  


  return (
    <div className="document-download">
        {props.component.label}
        <a target="_blank" href={props.component.file}><img src="/document.png" /> {props.component.buttonLabel}</a>
    </div>
  );
}

export default DocumentDownload;