import React, { Component, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';

import './SendSummary.scss';
import ISendSummary from '../interfaces/SendSummary';
import {FormContext} from '../../../context/FormContext';
import KeyReplace from '../../KeyReplace';

interface IProps {
  finished?:boolean;
  component?:ISendSummary;
}

interface Signer {
  name:string;
  title:string;
  email:string;
}

const SendSummary:FunctionComponent<IProps> = props => {
  
  const formContext = useContext(FormContext);

  //const { fontSize, ref } = useFitText({ maxFontSize: 120, minFontSize: 200 });

  let [numberHolder, setNumberHolders] = useState<string>("1");
  let [applicationType, setApplicationType] = useState<string>('');
  let [isInstant, setIsInstant] = useState<Boolean>(false);

  React.useEffect(() => {
    let numberOfHolders = formContext.formData?.get("investment-application.account-holders.number-of-owners");
    if(numberOfHolders) {
        setNumberHolders(numberOfHolders);
    }
    let applicationTypeS = formContext.formData?.get("investment-application.choose-account-type.account-type-select");
    if(applicationTypeS) {
      setApplicationType(applicationTypeS);
    }    
    let bankVerify = formContext.formData?.get("system.bank-verify");
    if (bankVerify == 'instant') {
      setIsInstant(true);
    } else {
      setIsInstant(false)
    }
   }, [formContext.formRevision])

   function getSigners() {
    let signers:Signer[] = [];
    if (props.component) {
      let signerString:string[] = props.component.signers?.split(",") || [];
    if (signerString.length > 0) {
       signerString.forEach((subSigner:string) => {
         let splitSigner:string[] = subSigner.split('|');
         let signer = {name : replaceText(splitSigner[0]), title : replaceText(splitSigner[1]), email : replaceText(splitSigner[2])}
         if (!signer.name.startsWith('{') && !signer.name.endsWith('}')) {
            signers.push(signer);
         }
         
       })
    } 
    }
    

    return signers;
  }

  function replaceText(text:string):string {
    let newString = text;
    if (formContext.formData) {
      Array.from(formContext.formData).forEach((value:[string,string]) => 
        newString = newString.replaceAll("{" + value[0] + "}", value[1])
      )
    }
  
    return newString;
  }

  return (
    <div className={"send-summary " + (props.component?.style ? props.component.style : '')}>
      {!props.component?.v2 && !props.component?.v3 &&
        <Fragment>
        {!props.finished && !props.component?.title && <h2>Review Your Email Information</h2>}
        {props.component?.title && <h2>{props.component.title}</h2>}
        {props.finished && <h2>Check Your Inbox</h2>}
        <p>
        {numberHolder === '1' && applicationType !== "corporate" && 
        <KeyReplace string={
            !props.finished ? "Once you submit your online investment application, a completed copy of the application will be emailed to the {system.primary-owner-title} at the following email address:"
            : "Once your application is processed, a completed copy of the application will be emailed to the {system.primary-owner-title} at the following email address:"
        }/>}
        {numberHolder === '2' && 
        <KeyReplace string={
            !props.finished ? "Once you submit your online investment application, a completed copy of the application will be emailed to the {system.primary-owner-title} and {system.joint-owner-title} at the following email addresses:"
            : "Once your application is processed, a completed copy of the application will be emailed to the {system.primary-owner-title} and {system.joint-owner-title} at the following email addresses:"
        }/>}
        {applicationType === "corporate" && 
        <KeyReplace string={
            !props.finished ? "Once you submit your online investment application, a completed copy of the application will be emailed to the Organization Representitive at the following email address:"
            : "Once your application is processed, a completed copy of the application will be emailed to the Organization at the following email address:"
        }/>}
        </p>
        {(numberHolder === '1' || numberHolder === '2') && applicationType !== 'corporate' &&
        <div className="email-user">
            <span className="email-user-name"><KeyReplace string={"{investment-application.personal-information.primary-owner-information.first-name} {investment-application.personal-information.primary-owner-information.last-name}"}/></span>
            <span className="email-user-title"><KeyReplace string={"{system.primary-owner-title}"}/></span>
            <span className="email-user-email"><KeyReplace string={"{investment-application.personal-information.primary-owner-information.email-address}"}/></span>
        </div>
        }
        {numberHolder === '2' && applicationType !== 'corporate' &&
            <div className="email-user">
            <span className="email-user-name"><KeyReplace string={"{investment-application.personal-information.joint-owner-information.first-name} {investment-application.personal-information.joint-owner-information.last-name}"}/></span>
            <span className="email-user-title"><KeyReplace string={"{system.joint-owner-title}"}/></span>
            <span className="email-user-email"><KeyReplace string={"{investment-application.personal-information.joint-owner-information.email-address}"}/></span>
            </div>
        }
        {applicationType === 'corporate' && 
        <div className="email-user">
            <span className="email-user-name"><KeyReplace string={"{investment-application.personal-information.rep-information.first-name} {investment-application.personal-information.rep-information.last-name}"}/></span>
            <span className="email-user-title"><KeyReplace string={"Organization Representitive"}/></span>
            <span className="email-user-email"><KeyReplace string={"{investment-application.personal-information.rep-information.email-address}"}/></span>
        </div>
        }
        <p>Once you receive the application, follow the instructions in the email to sign the application online. Our E-Signature process is fast, secure, and convienient, if you have any questions, please contact us.</p>
        </Fragment>
}
        {props.component && props.component.v2 &&
        <Fragment>
          {!props.finished && !props.component?.title && <h2>Review Your Email Information</h2>}
          {props.component?.title && <h2>{props.component.title}</h2>}
          {props.finished && <h2>Check Your Inbox</h2>}
          <p><KeyReplace string={props.component.intro || ''} /></p>
          {getSigners().map((signer:Signer, index:number) =>
          <div key={`signer-${index}`} className="email-user">
          <span className="email-user-name">{signer.name}</span>
          <span className="email-user-title">{signer.title}</span>
          <span className="email-user-email">{signer.email}</span>
          </div>
          )}
          <p><KeyReplace string={props.component.outro || ''} /></p>
        </Fragment>
       }

        {props.component && props.component.v3 &&
        <Fragment>
          <ol>
            <li>
              <h2>Check Your Email</h2>
              <p><KeyReplace string={props.component.intro || ''} /></p>
              {getSigners().map((signer:Signer, index:number) =>
              <div key={`signer-${index}`} className="email-user">
              <span className="email-user-name">{signer.name}</span>
              <span className="email-user-title">{signer.title}</span>
              <span className="email-user-email">{signer.email}</span>
              </div>
              )}
            </li>
            <li>
              <h2>Sign Your Paperwork Online</h2>
              <p><KeyReplace string={props.component.outro || ''} /></p>
            </li>
            {!isInstant && <li>
              <h2>Fund Your Account</h2>
              <p>Mail or email a voided check for electronic funds transfer. Another option is to send us a check for the amount on the form.</p>
              <p>You can mail to:<br/>
Church Investors Fund<br/>
9401 E. Stockton Blvd<br/>
Elk Grove, CA 95624</p>
            </li>}
          </ol>
        </Fragment>
       }
    </div>
  );
}

export default SendSummary;