import React, { Component, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';

import './ButtonPrompt.scss';
import IButtonPrompt from '../interfaces/ButtonPrompt';
import {FormContext} from '../../../context/FormContext';
import KeyReplace from '../../KeyReplace';


interface IProps {
  component:IButtonPrompt;
}

const ButtonPrompt:FunctionComponent<IProps> = props => {
  
  const formContext = useContext(FormContext);
  
  


  return (
    <p className="button-prompt">
        <KeyReplace string={props.component.content} />
    </p>
  );
}

export default ButtonPrompt;