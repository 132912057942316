import React, { Component, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';

import './Agreement.scss';
import IAgreement from '../interfaces/Agreement';
import {FormContext} from '../../../context/FormContext';
import KeyReplace from '../../KeyReplace';

interface IProps {
  component:IAgreement;
  parentAlias:string;
  validationHandler(alias:string, isValid:boolean):void;
}

const Agreement:FunctionComponent<IProps> = props => {
  
  const formContext = useContext(FormContext);

  let [inputValue, setInputValue] = useState<string>(getValue());
  let [isValid, setIsValid] = useState<boolean>();
  //let [isOverriden, setIsOverridden] = useState<boolean>();
  //let [isTouched, setIsTouched] = useState<boolean>(false);
  //let [hasFocus, setHasFocus] = useState<boolean>(false);
  let [hasError, setHasError] = useState<boolean>(false);

  function getAlias():string {
    return props.parentAlias + '.' + props.component.alias;
  }

  function onValueChange(e:React.ChangeEvent<HTMLInputElement>) {
    //setIsTouched(true);
    let value = '';
    if(e.target.checked) {
         value = e.target.value;
    }
    if(formContext.updateFormData) {
      formContext.updateFormData(getAlias(), value);
      setInputValue(value);
      
    }
    
  }
  



  function getValue():string {
    let value = formContext.formData?.get(getAlias());
    
    if(value) {
      return value;
    } else {
      return '';
    }
  }

  function validate() {
    //if (isTouched) {

    
    let isFieldValid = true;
    let override = false;
    
      
    if(!override && props.component.required) {
      if(!inputValue || !inputValue.trim()) {
        isFieldValid = false;  
      }
    }

    if(!override && props.component.validationRule) {
      //console.log('called');
      let validationRule = props.component.validationRule.split(' ');
      //console.log(validationRule);
      //console.log(validationRule[1]);
      let checkValue = formContext.formData?.get(validationRule[1]);
      //console.log(`checked value: ${checkValue} input value: ${inputValue}`)
      if (validationRule[0] === 'eq') {
        if (checkValue != inputValue) {
          isFieldValid = false;
        }
      }
      if (validationRule[0] === 'gte') {
        if (!checkValue) {
          checkValue = '0';
        }
        
        if (Number(checkValue) > Number(inputValue)) {
          //console.log('called 2222');
          isFieldValid = false;
        }
      }
      if (validationRule[0] === 'neq') {

      }
    }
    
    

      setIsValid(isFieldValid);
      props.validationHandler(getAlias(), isFieldValid);
    //}
  }

  function overrideChange(event:React.ChangeEvent<HTMLInputElement>) {
    
    if (event.target.checked) {
      if(formContext.updateFormData) {
        formContext.updateFormData(getAlias(), props.component.overrideValue || '');
        
      }
    //   setIsOverridden(true);
    //   props.component.overrideFields!.forEach((field:string) => {
    //     if(formContext.updateOverrideField) {
    //       formContext.updateOverrideField(formContext.formAlias + '.' + field, true)
    //     }
    //   });
    // } else {
    //   setIsOverridden(false);
    //   props.component.overrideFields!.forEach((field:string) => {
    //     if(formContext.updateOverrideField) {
    //       formContext.updateOverrideField(formContext.formAlias + '.' + field, false)
    //     }
    //   });
    }
    
  }

  React.useEffect(() => {
   validate();
  }, [formContext.formRevision])

  
//   function blurHandler() {
//     setHasFocus(false);
//   }
  
//   function focusHandler() {
//     setHasFocus(true);
//   }

//   React.useEffect(() => {
//     if(!hasFocus && isTouched && !isValid) {
//       setHasError(true);
//     } else {
//       setHasError(false);
//     }
//   }, [hasFocus, isTouched, isValid])


  return (
    <Fragment>
      
    <div className={"agreement-box " + (hasError ? 'error' : '')}>
        <label className={props.component.hideLabel === true ? 'hidden' : ''} htmlFor={getAlias()}><KeyReplace string={props.component.label} /></label>
        <input type="checkbox" name={getAlias()} id={getAlias()}  onChange={onValueChange} checked={inputValue ? true : false} value="true" />
    </div>
    </Fragment>
  );
}

export default Agreement;