import React, { Component, Fragment, FunctionComponent, useContext, useEffect, useState } from 'react';

import './Alert.scss';
import IAlert from '../interfaces/Alert';
import {FormContext} from '../../../context/FormContext';
import KeyReplace from '../../KeyReplace';


interface IProps {
  component:IAlert;
}

const Alert:FunctionComponent<IProps> = props => {
  
  const formContext = useContext(FormContext);
  
  


  return (
    <div className="alert">
        <KeyReplace string={props.component.content} />
    </div>
  );
}

export default Alert;