import { stringify } from 'querystring';
import React, { FunctionComponent, Fragment, useContext, useEffect, useState } from 'react';

import {FormContext} from '../../context/FormContext';
import FormSection from '../../interfaces/FormSection';
import './ProgressTrack.scss';
interface IProps {
  alias:string;
  label:string;
  maxStep:number;
  index:number;
  currentStep:number;
  goToStep(number:number):void;
  hasError:boolean;
}



const ProgressItem:FunctionComponent<IProps> = props => {

    
  const formContext = useContext(FormContext);

  
  function getStepClasses(step:number):string {
    let progressClass:string = '';
    if (step == props.currentStep) {
        progressClass = progressClass + 'current-step '
    }
    if (step <= props.maxStep) {
        progressClass = progressClass + 'unlocked-step '
    }
    if (step > props.maxStep) {
        progressClass = progressClass + 'locked-step '
    }
    if (step == props.maxStep) {
      progressClass = progressClass + 'max-step '
    }
    return progressClass;
  }
      
  function onClickHandler(step:number) {
    if(step <= props.maxStep) {
        props.goToStep(step);
    }
  }

  


  

  return (
      
            <li key={`prog-${props.alias}-`} className={"progress-step " + getStepClasses(props.index) + " " + (props.hasError ? 'error' : '')} onClick={() => onClickHandler(props.index)}><span className="progress-label">{props.label}</span></li>
          
  );
}

export default ProgressItem;