import React, { FunctionComponent, useEffect, useCallback, useState, useContext, Component, Fragment } from 'react';
import axios from 'axios';
import ISelectOne from '../interfaces/SelectOne';
import FormItemChoice from '../../../interfaces/FormItemChoice';
import FormItemBigImageButtons from '../FormItemBigImageButtons';
import FormItemBigVerticalButtons from '../FormItemBigVerticalButtons';
import { FormContext } from '../../../context/FormContext';
import { on } from 'events';
import { usePlaidLink, PlaidLinkOptions } from 'react-plaid-link';
import SystemAccountReference from '../../../interfaces/SystemAccountReference';


interface IProps {
  component:ISelectOne;
  parentAlias:string;
  validationHandler(alias:string, isValid:boolean):void;
}

const SelectOne:FunctionComponent<IProps> = props => {

  const formContext = useContext(FormContext);
  
  let [checkedValue, setCheckedValue] = useState<string>(getValue());
  let [isValid, setIsValid] = useState<boolean>();

  function onValueChange(value:string) {
    if(formContext.updateFormData) {
      formContext.updateFormData(props.parentAlias + "." + props.component.alias, value);
      setCheckedValue(value);
    }
  }

  function getAlias():string {
    return props.parentAlias + "." + props.component.alias;
  }
 
  function getValue():string {
    
    let value = formContext.formData?.get(getAlias());
    
    if(value) {
      return value;
    } else {
      return '';
    }
  }

  function validate() {
    
    let isFieldValid = true;
    
   // console.log(checkedValue);
    if(props.component.required) {
      if(!checkedValue || !checkedValue.trim()) {
        isFieldValid = false;  
      }
    }
    
    if(!isSelectionVisible(checkedValue) && props.component.dataType != 'rates') {
     // console.log('seleciton not visisble, field should be invalid: ' +  checkedValue);
      isFieldValid = false;
    }
    
      setIsValid(isFieldValid);
      props.validationHandler(getAlias(), isFieldValid);

  }



  function isSelectionVisible(value:string):boolean {
   
    
    let choice:FormItemChoice | undefined = props.component.choices.find((choice:FormItemChoice) => choice.value === value);
    //console.log(choice);
    if(choice) {
      if(showIf(choice)) {
        //console.log('selection visibile' + choice?.label);
        return true;
        
      }
    } 
    //console.log('selection not visible:' + choice?.label)
    //if(formContext.removeFormData) {
    //  formContext.removeFormData(props.parentAlias + "." + props.component.alias);
    //}
    // if (formContext.formData?.get(props.parentAlias + "." + props.component.alias) === choice?.value) {
    //   if(formContext.removeFormData) {
    //   formContext.removeFormData(props.parentAlias + "." + props.component.alias);
    //   }
    // } 
    
    return false
      

    
  }

  function showIf(formItem:any):boolean {
    
    // let show = true;
     let showList:boolean[] = [];
     let multitype = '';
     
     if(formItem.hasOwnProperty("showIf")) {
       let expressions:string[] = [];
       
       if(formItem.showIf.includes('||')) {
         
         multitype = "or";
         expressions = formItem.showIf.split("||");
       }else if(formItem.showIf.includes('&&')) {
         
         multitype = "and";
         expressions = formItem.showIf.split("&&");
       } else {
         expressions = formItem.showIf.split("||");
       }
       
       //console.log(expressions);
       expressions.forEach((expression:string) => 
      { let expressionArray:string[] = expression.split(" ");
       //lets extract the fields
       let show = false;
       let value = formContext.formData?.get(formContext.formAlias + '.' + expressionArray[0]);
       value = value?.toLowerCase();
       let compareValue = expressionArray[2].replaceAll("+", " ");
       compareValue = compareValue.toLowerCase();
       //console.log(value + " " + expressionArray[1] + " " + compareValue);
       if(expressionArray[1] === 'eq') {
         
         if(value === compareValue) {
           show = true;
         } 
       }
       if(expressionArray[1] === 'neq') {
         
         if(value != compareValue) {
           show = true;
         } 
       }
       if(expressionArray[1] === 'gte') {
         
         if(Number(value) >= Number(compareValue)) {
           show = true;
         } 
       }
         showList.push(show);
       }
       );
 
 
       
       
     }
     
     if(multitype != '') {
      
      if (multitype === 'or') {
        let final:boolean = false;
        showList.forEach((value:boolean) => {
          if(value) {
            final = true;
          }
        })
        return final;
      }
      if (multitype === 'and') {
        let final:boolean = true;
        showList.forEach((value:boolean) => {
          if(!value) {
            final = false;
          }
        })
        return final;
      }
    }
     if (showList.length === 0) {
       return true;
     }
     return showList[0];
   }


  React.useEffect(() => {
   validate();
  }, [formContext.formRevision])

  function getLayout(formItem:ISelectOne) {
    switch(formItem.layout) {
        case 'big-image-buttons': {
            return <FormItemBigImageButtons checkedValue={checkedValue} parentAlias={getAlias()} onValueChange={onValueChange} component={formItem} />;
        }
        case 'big-vertical-buttons' : {
            return <FormItemBigVerticalButtons checkedValue={checkedValue} parentAlias={getAlias()} onValueChange={onValueChange} component={formItem} />;
        }
    }
    return <div></div>
}

function onReset() {
  
  setCheckedValue('');
}

  return (
    <Fragment>
      {getLayout(props.component)}
    </Fragment>
  );
}

export default SelectOne;